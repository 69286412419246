@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: #f1f1f1;
  font-family: "Kanit";
}

.container {
  margin-top: 80px;
  padding: 0;
  margin-bottom: 30%;
}

.ce-popover {
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
}
