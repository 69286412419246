.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.nav__bar--container {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 1.4em;
}
.nav__bar--icon {
  font-size: 2em;
  margin: 0;
  color: #000000;
}
.nav__bar--action {
  margin: 0;
  padding: 0;
  width: 40px;
}
.nav__bar--link__container, .nav__bar--active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.nav__bar--active .nav__bar--icon,
.nav__bar--active .nav__bar--link__title {
  color: #06c167;
}
.nav__bar--link__title {
  margin: 0;
  font-size: 0.9em;
  color: #353635;
  text-decoration: none;
}

