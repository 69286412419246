// Color

$primary__color: #06c167;
$secondary__color: #898989;
$third__color: #353635;
$black: #000000;
$white: #ffffff;

// Border styles
$border-radius: 15px;
$border: 0.1em solid #00000050;
@mixin imageSize {
  width: 100%;
  height: 100%;
}

// Mixin with arguments

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}
.box {
  @include transform(rotate(30deg));
}

@mixin mainMargin($top, $left) {
  margin: $top $left;
}

@mixin mainPadding($top, $left) {
  padding: $top $left;
}

@mixin reset {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin fontSize($property) {
  font-size: $property;
}

@mixin sectionFlexDisplayed($align, $justify, $direction) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

@mixin sectionGridDisplayed($column, $gap) {
  display: grid;
  grid-template-columns: $column;
  grid-gap: $gap;
}
