@import "../../styles/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&display=swap");

.article__table {
  &--container {
    font-family: "Kanit" !important;
    margin: 1em 0;
  }

  &--head {
    background-color: $primary__color;

    padding: 0;
    height: 50px;

    th {
      padding: 0 0.5em;
      color: $white;
    }
  }

  &--row {
    padding: 0;
  }
}

.image__container {
  width: 100px;
  height: 100px;

  img {
    @include imageSize;
  }
}


.table__item {
  &--cell {
    align-items: flex-start;
    justify-content: flex-start;
    a {
      color: $black;
      text-decoration: none;
    }
  }

  &--user__actions  {
    // @include sectionFlexDisplayed(center, space-between, row);
    height: 100%;
  }

  &--edit, &--delete{
    color: $black;
    font-size: 1.6em;
    margin-right: 0.5em;
  }
}  