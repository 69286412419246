.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.container {
  margin-top: 60px;
  padding: 0;
}

.title {
  font-size: 1.4em;
}

.section__container--heading {
  display: flex;
  justify-content: space-between;
}
.section__container--table__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 1em;
}
.section__container--see__all__link {
  align-self: flex-end;
  color: #06c167;
}

