@import "../../styles/variables.scss";

.single__article {
  &--container {
  }
  &--upload__container {
    @include sectionFlexDisplayed(center, center, row);
  }
  &--action__container {
    @include sectionFlexDisplayed(center, space-around, row);
    width: 100%;
  }
  &--edit__button,
  &--delete__button,
  &--remove__picture {
    border: none;
    width: 40%;
    padding: 0.5em;
    @include fontSize(1.2em);
    border-radius: 5px;
    color: $white;
  }

  &--edit__button {
    background-color: $primary__color;
    a {
        color: $white;
        text-decoration: none;
    }
  }
  &--delete__button,
  & {
    background-color: #ff1616;
  }
}

.article-image {
  width: 300px;
  height: 300px;

  img {
    @include imageSize;
  }
}
