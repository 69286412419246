.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.single__article--upload__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.single__article--action__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
}
.single__article--edit__button, .single__article--delete__button, .single__article--remove__picture {
  border: none;
  width: 40%;
  padding: 0.5em;
  font-size: 1.2em;
  border-radius: 5px;
  color: #ffffff;
}
.single__article--edit__button {
  background-color: #06c167;
}
.single__article--edit__button a {
  color: #ffffff;
  text-decoration: none;
}
.single__article--delete__button, .single__article {
  background-color: #ff1616;
}

.article-image {
  width: 300px;
  height: 300px;
}
.article-image img {
  width: 100%;
  height: 100%;
}

