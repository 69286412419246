.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.login__page--container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.login__page--image__container {
  width: 80px;
  height: 80px;
  margin: 2em 0;
}
.login__page--image__container img {
  width: 100%;
  height: 100%;
}
.login__page--input, .login__page--action__button {
  width: 100%;
  margin: 1em 0;
}
.login__page--action__button {
  border: none;
  padding: 0.5em;
  font-size: 1.2em;
  border-radius: 5px;
  color: #ffffff;
  background-color: #06c167;
}

