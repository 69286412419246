@import "../../styles/variables.scss";

.publish__form {
  &--container {
  }
  &--upload__container {
    @include sectionFlexDisplayed(center, center, row);
  }
  &--action__container {
    @include sectionFlexDisplayed(center, space-around, row);
    width: 100%;
  }
  &--submit__button,
  &--cancel__button,
  &--remove__picture {
    border: none;
    width: 40%;
    padding: 0.5em;
    @include fontSize(1.2em);
    border-radius: 5px;
    color: $white;
  }

  &--editor {
    border: 0.1em solid red !important;
  }

  &--submit__button {
    background-color: $primary__color;
  }
  &--cancel__button,
  &--remove__picture {
    background-color: $secondary__color;
  }
  &--remove__picture {
    @include fontSize(0.8em);
    padding: 0.5em;
  }
}

.codex-editor,
.codex-editor--narrow,
.codex-editor--empty {
  .codex-editor__redactor {
    padding-bottom: 100px !important;
  }
}
