@import "../../styles/variables.scss";

.login__page {
  &--container {
    @include sectionFlexDisplayed(center, center, column);
    height: 100vh;
  }
  &--image__container {
    width: 80px;
    height: 80px;
    margin: 2em 0;

    img {
      @include imageSize;
    }
  }

  &--input,
  &--action__button {
    width: 100%;
    margin: 1em 0;
  }

  &--action__button {
    border: none;
    padding: 0.5em;
    @include fontSize(1.2em);
    border-radius: 5px;
    color: $white;
    background-color: $primary__color;
  }
}
