@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&display=swap");
.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.article__table--container {
  font-family: "Kanit" !important;
  margin: 1em 0;
}
.article__table--head {
  background-color: #06c167;
  padding: 0;
  height: 50px;
}
.article__table--head th {
  padding: 0 0.5em;
  color: #ffffff;
}
.article__table--row {
  padding: 0;
}

.image__container {
  width: 100px;
  height: 100px;
}
.image__container img {
  width: 100%;
  height: 100%;
}

.table__item--cell {
  align-items: flex-start;
  justify-content: flex-start;
}
.table__item--cell a {
  color: #000000;
  text-decoration: none;
}
.table__item--user__actions {
  height: 100%;
}
.table__item--edit, .table__item--delete {
  color: #000000;
  font-size: 1.6em;
  margin-right: 0.5em;
}

