@import "../../styles/variables.scss";
.nav__bar {
  &--container {
    width: 100%;
    margin: 0;
    padding: 0;
    @include sectionFlexDisplayed(center, space-between, row);
    padding: 0 1.4em;
  }

  &--icon {
    font-size: 2em;
    margin: 0;
    color: $black;
  }
  &--action {
    margin: 0;
    padding: 0;
    width: 40px;
  }

  &--link__container,
  &--active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  &--active {
    .nav__bar--icon,
    .nav__bar--link__title {
      color: $primary__color;
    }
  }

  &--link__title {
    margin: 0;
    font-size: 0.9em;
    color: $third__color;
    text-decoration: none;
  }
}
