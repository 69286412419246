.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.publish__form--upload__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.publish__form--action__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
}
.publish__form--submit__button, .publish__form--cancel__button, .publish__form--remove__picture {
  border: none;
  width: 40%;
  padding: 0.5em;
  font-size: 1.2em;
  border-radius: 5px;
  color: #ffffff;
}
.publish__form--editor {
  border: 0.1em solid red !important;
}
.publish__form--submit__button {
  background-color: #06c167;
}
.publish__form--cancel__button, .publish__form--remove__picture {
  background-color: #898989;
}
.publish__form--remove__picture {
  font-size: 0.8em;
  padding: 0.5em;
}

.codex-editor .codex-editor__redactor,
.codex-editor--narrow .codex-editor__redactor,
.codex-editor--empty .codex-editor__redactor {
  padding-bottom: 100px !important;
}

