@import "../../styles/variables.scss";
.toolbar {
  background-color: $white;
  display: flex;
  justify-content: space-between;

  h3 {
    color: $black;
  }
  position: fixed;
}
.logo__container {
  width: 60px;
  height: 60px;

  img {
    @include imageSize;
  }
}

.menu__item {
  &--container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .span {
      font-weight: 700;
      font-size: 1em;
    }
  }

  &--icon {
    font-size: 1em;
  }
}
