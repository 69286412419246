.box {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.toolbar {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  position: fixed;
}
.toolbar h3 {
  color: #000000;
}

.logo__container {
  width: 60px;
  height: 60px;
}
.logo__container img {
  width: 100%;
  height: 100%;
}

.menu__item--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu__item--container .span {
  font-weight: 700;
  font-size: 1em;
}
.menu__item--icon {
  font-size: 1em;
}

