@import "../../styles/variables.scss";

.container {
  margin-top: 60px;
  padding: 0;
}

.title {
  @include fontSize(1.4em);
}

.section__container {
  &--heading {
    display: flex;
    justify-content: space-between;
  }

  &--table__wrapper {
    @include sectionFlexDisplayed(center, center, column);
    margin: 0 0 1em;
    
  }

  &--see__all__link {
    align-self: flex-end;
    color: $primary__color;
    
  }
}

